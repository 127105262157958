<template>
  <div>
    <init-loading :isInitLoading.sync='isInitLoading'></init-loading>
    <div class='radius_bg'>
      <el-form :model='searchCondition' inline size='mini' @submit.native.prevent>
        <el-form-item>
          <el-date-picker
            v-model='searchCondition.date_range'
            type='daterange'
            align='right'
            unlink-panels
            range-separator='至'
            start-placeholder='录入开始日期'
            end-placeholder='录入结束日期'
            value-format='yyyy-MM-dd'
            style='width: 280px'
            :picker-options='pickerOptions'>
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <!--            <LeaderSearch :keyword.sync="searchCondition.leader_name" @selected="selectedLeader"/>-->
          <ProductLeaderSelect ref='refProductLeader' :leader-id.sync='searchCondition.leader_id'
                               @selected='selectedLeader' v-if="userPermissions.indexOf('product_view_all') > -1" />
          <span v-else>{{ userInfo.nickname }} </span>
        </el-form-item>
        <el-form-item>
          <CategoryCascader :identify='`product`' @cascaderChange='cascaderChange' />
        </el-form-item>
        <el-form-item>
          <el-input placeholder='商品名称' v-model='searchCondition.title' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item>
          <el-select @change='handleQuery' clearable v-model='searchCondition.unify' placeholder='是否统一机制' style="width: 140px">
            <el-option label='是' value='1'></el-option>
            <el-option label='否' value='2'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select clearable v-model='searchCondition.brand_id' filterable style='width: 100%' @focus='getBrand'
                     placeholder='请选择品牌'>
            <el-option
              v-for='item in brandOptions'
              :key='item.id'
              :label='item.name'
              :value='item.id'>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="modePrice" style="width: 100px" placeholder="">
            <el-option key="price" label="日常价" value="price"></el-option>
            <el-option key="lb_price" label="直播价" value="lb_price"></el-option>
          </el-select>
          <el-input placeholder='最低价' style="width: 100px" v-model='price[0]'
                    clearable/>
          ~
          <el-input placeholder='最高价' style="width: 100px" v-model='price[1]'
                    clearable/>
        </el-form-item>
        <el-form-item label='季节性'>
          <el-select @change='handleQuery' clearable v-model='searchCondition.used_season' placeholder='季节性' style="width: 120px">
            <el-option label='春' value='春'></el-option>
            <el-option label='夏' value='夏'></el-option>
            <el-option label='秋' value='秋'></el-option>
            <el-option label='冬' value='冬'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input placeholder='可播平台' style="width: 120px" v-model='searchCondition.platform_name' @change='handleQuery' clearable />
        </el-form-item>
        <el-form-item>
          <el-select clearable
                     v-model='searchCondition.online_cmn'
                     filterable
                     style='width: 140px'
                     @change='handleQuery'
                     placeholder='筛选线上佣金'>
            <el-option v-for='(item,key) in onlineCmnOptions' :key='key' :label='item.name' :value='item.value' />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class='radius_bg'>

      <div style='display: flex;flex-direction: row;gap: 5px;justify-content: space-between;margin-bottom: 8px;'>
        <el-row :gutter='10'>
          <el-col :span='1.5'>
            <export-btn type='primary' :plain='true' ref='refExportLink' :lint-title='`导出(含图)`'
                        title='适合场景：数据量较小，比如小于1000条数据'
                        :can-export="userPermissions.indexOf('product_export')>-1"
                        @export='exportExcel'></export-btn>
          </el-col>
          <el-col :span='1.5'>
            <el-button icon='el-icon-download' @click='asyncExportExcel' type='warning' :plain='true'
                       title='适合场景：数据量较大,商品图片将被忽略'
                       v-if="userPermissions.indexOf('product_export')>-1" :loading='exportLoading'
                       :disabled='exportLoading'>
              导出表格
            </el-button>
          </el-col>
        </el-row>
        <div>
          <el-radio-group v-model='mod'>
            <el-radio-button label='Calendar'>卡片</el-radio-button>
            <el-radio-button label='Table'>列表</el-radio-button>
          </el-radio-group>
        </div>

      </div>

      <div>
        <!--      旧卡片-->
        <product-old-card v-show="mod==='Calendar'" :dataList='dataList' :loading='loading'
                          @handleCheck='handleCheck' @handleDetails='handleDetails'
                          @handleJoinLive='handleJoinLive' @handleEdit='handleEdit'></product-old-card>

        <!-- 新卡片-->
        <product-card v-show="mod==='CalendarNew'" :dataList='dataList' :loading='loading'
                      @handleCheck='handleCheck' @handleDetails='handleDetails'
                      @handleJoinLive='handleJoinLive' @handleEdit='handleEdit'></product-card>
        <!--      表格-->
        <div class='default-table'>
          <el-table :data='dataList'
                    row-key='id' v-show="mod==='Table'" @selection-change='handleSelectionChange'
                    @row-contextmenu='openMenu'
                    ref='multipleTable' @sort-change='changeTableSort'
                    highlight-current-row>
            <el-table-column :reserve-selection='true' type='selection' width='55'></el-table-column>
            <el-table-column type='index' label='序号' width='55' align='center'></el-table-column>
            <el-table-column label='最后更新' prop='updated_at' width='135' align='center' sortable='custom'>
              <template v-slot='{row:{updated_at}}'>
                <span>{{ updated_at|parseTime('{y}/{m}/{d} {h}:{i}') }}</span>
              </template>
            </el-table-column>
            <el-table-column label='商品名称' prop='title' width='160' show-overflow-tooltip align='center'>
              <template slot-scope='{row}'>
                <span>{{ row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label='产品图' prop='cover' width='70' align='center'>
              <template slot-scope='{row}'>
                <product-image style='width: 40px;height: 40px' :image-url='row.cover_url' />
              </template>
            </el-table-column>
            <el-table-column label='秒杀品' width='70' align='center'>
              <template v-slot="{row:{is_sec_kill}}">
                {{ is_sec_kill === 'Y' ? '是' : '否'}}
              </template>
            </el-table-column>
            <el-table-column label='类目' prop='category_id' min-width='120' align='center' sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.category || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='可播平台' prop='platform_name' min-width='120' align='center'>
              <template slot-scope='{row}'>
                <span>{{ String(row.platform_names) || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='规格' prop='spec_name' align='center' min-width='100'></el-table-column>
            <el-table-column label='品牌' prop='brand_name' align='center' min-width='100'></el-table-column>
            <el-table-column align='center' label='色号/香型' min-width='100' prop='spec_color'></el-table-column>
            <el-table-column label='合作方式' prop='mode' header-align='center' align='center' min-width='110'
                             sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.mode_alias || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='日常价(元)' align='center' min-width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.price || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='直播价(元)' align='center' min-width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.lb_price || '/' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='赠品' prop='gift' align='center' min-width='140' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.gift || '/' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='独家机制' prop='other_activity' align='center' min-width='120'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.other_activity || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='坑位费(元)' prop='ad_space_fee' align='center' min-width='120' sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.ad_space_fee || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='线上佣金(%)' prop='online_cmn' align='center' min-width='120' sortable='custom'>
              <template slot-scope='{row}'>
                <span class='rate'>{{ row.online_cmn || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='线下佣金(%)' prop='offline_cmn' align='center' min-width='120' sortable='custom'>
              <template slot-scope='{row}'>
                <span class='rate'>{{ row.offline_cmn || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='负责人' prop='leader_id' align='center' min-width='100' sortable='custom'>
              <template slot-scope='{row}'>
                <span>{{ row.leader_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='备注信息' prop='remark' align='center' min-width='140' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.remark || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='店铺名称' prop='company_name' align='center' min-width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.company_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='店铺注册公司' show-overflow-tooltip prop='mini_customer_name' width='160'
                             align='center'>
            </el-table-column>
            <el-table-column label='产品链接' prop='product_url' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <el-link type='primary' underline :href='row.product_url' target='_blank'>点击查看</el-link>
              </template>
            </el-table-column>
            <el-table-column label='创建时间' prop='created_at' min-width='140' align='center'>
              <template slot-scope='{row}'>
                <span :title='`修改时间：${ row.updated_at}`'>{{ row.created_at || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='操作' align='center' width='160' fixed='right'>
              <template slot-scope='{row}' style='display: flex'>
                <el-button type='text' icon='el-icon-edit' @click='handleEdit(row)'
                           v-if="userPermissions.indexOf('product_edit')>-1">修改
                </el-button>
                <!--              <el-button type='text' icon='el-icon-document-copy' @click='handleCopy(row)'-->
                <!--                         v-if="userPermissions.indexOf('product_create')>-1">复制-->
                <!--              </el-button>-->
                <el-button type='text' icon='el-icon-delete' @click='handleDelete(row)'
                           v-if="(userPermissions.indexOf('product_delete')>-1&&userInfo.id===row.leader_id)||userPermissions.indexOf('product_delete_all')>-1">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--        分页-->
        <div style="display: flex;justify-content: space-between;align-items: center;">
          <div>
            <!-- 注意事项 -->
            <span style="color: #8a8e99;"><i class="el-icon-warning" style="margin-right: 4px;" />表格内右键菜单，可进行各项操作（修改、复制、删除、日志）</span>
          </div>
          <pagination :hidden='total===0'
                      :background='true'
                      :total.sync='total'
                      :page.sync='pageData.current_page'
                      :autoScroll='true' :limit.sync='pageData.page_size'
                      @pagination='getList' />
        </div>
      </div>

    </div>
    <!--    创建产品-->
    <ProductCreateEdit :visible.sync='open' @close='getList' :id='currId' :default-form-data='copyRow' />
    <!--    右键菜单-->
    <Contextmenu :id='currentId' :menu-data='menuData' :menuList='menuList' @change='handleCmd' />

    <!--   -PPT模式预览 -->
    <previewPPT :id='currId' :visible.sync='showPreview' fullscreen @close='getList'></previewPPT>
    <ProductLog :info-id='currId' :visible.sync='openLog' />
    <JoinLive :productId='productId' :visible.sync='showJoinlive'></JoinLive>
    <edit-specimen @submitData='handelStore' :visible.sync='dialogStore' :formStore.sync='formStore'></edit-specimen>
    <product-details :visible.sync='openDetails' :rowDetails='rowDetails' />
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ProductCreateEdit from '@/pages/lb/product/ProductCreateEdit'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import { mapGetters } from 'vuex'
import Contextmenu from '../Contextmenu'
import ProductLog from '@/pages/lb/product/ProductLog'
import LeaderSearch from '@/pages/lb/components/LeaderSearch'
import ProductImage from '../ProductImage'
import ProductLeaderSelect from '@/pages/lb/components/ProductLeaderSelect'
import ExportPpt from '@/pages/lb/components/ExportPpt'
import ExportProductPpt from '@/pages/lb/components/ExportProductPpt'
import previewPPT from '@/pages/lb/product/PreviewPPT.vue'
import JoinLive from '@/pages/lb/product/JoinLive.vue'
import NewProduct from '@/pages/lb/product/NewProduct.vue'
import ProductSpecimen from '@/pages/lb/product/ProductSpecimen.vue'
import EditSpecimen from '@/pages/lb/product/EditSpecimen.vue'
import ProductSpecimenSave from '../../components/ProductSpecimenSave.vue'
import ProductRecycle from '../ProductRecycle.vue'
import ProductHistory from '../history/sync.vue'
import ProductOldCard from '../ProductOldCard'
import ProductDetails from '../ProductDetails'
import ProductTransfer from '@/pages/lb/product/component/transfer.vue'

export default {
  components: {
    ProductTransfer,
    // ProductVersionList,
    ProductHistory,
    ProductRecycle,
    ProductSpecimenSave,
    EditSpecimen,
    ExportProductPpt,
    ExportPpt,
    ProductLeaderSelect,
    ProductImage,
    LeaderSearch,
    ProductLog,
    Contextmenu, CategoryCascader, ProductCreateEdit, ExportBtn, PageHeaderLayout,
    JoinLive,
    NewProduct,
    ProductSpecimen,
    ProductDetails,
    previewPPT,
    ProductOldCard
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    currentId() {
      return this.currentRow ? this.currentRow.id : null
    }

  },
  data() {
    return {
      tipsList: [
        '1、表格内右键菜单，可进行各项操作（修改、复制、删除、日志）'
      ],
      loadingBrandStatus: false,
      brandOptions: [],
      searchCondition: {
        leader_id: null,
        category_id: null,
        title: null,
        unify: null,
        date_range: [],
        platform_name: null,
        is_sec_kill: 'Y',
        online_cmn: null,
        used_season: null,
      },
      modePrice: 'price',
      price: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      onlineCmnOptions: [
        // 线上佣金筛选项
        { value: '0~10', name: '0~10' },
        { value: '10~20', name: '10~20' },
        { value: '20~30', name: '20~30' },
        { value: '30~40', name: '30~40' },
        { value: '40以上', name: '40以上' }
      ],
      dataList: [],
      total: 0,
      orderSort: { id: 'desc' },
      pageData: { page_size: 10, current_page: 1 },
      loading: false,
      exportLoading: false,
      // columns: [
      //   {title: '录入时间', value: 'created_at', width: 120, align: 'center', header_align: 'center'},
      //   {title: '产品图', value: 'cover_pc_url', width: 100, align: 'center', header_align: 'center', type: 'image'},
      //   {title: '类目', value: 'category_id', width: 120, align: 'center', header_align: 'center',},
      //   {title: '规格', value: 'spec_name', width: 100, align: 'center', header_align: 'center',},
      //   {title: '合作方式', value: 'mode', width: 100, align: 'center', header_align: 'center',},
      //   {title: '日常价', value: 'price', width: 100, align: 'center', header_align: 'center',},
      //   {title: '直播价', value: 'lb_price', width: 100, align: 'center', header_align: 'center',},
      //   {title: '赠品', value: 'gift', width: 100, align: 'center', header_align: 'center',},
      //   {title: '其他活动', value: 'other_activity', width: 100, align: 'center', header_align: 'center',},
      //   {title: '线上佣金', value: 'online_cmn', width: 100, align: 'center', header_align: 'center',},
      //   {title: '线下佣金', value: 'offline_cmn', width: 100, align: 'center', header_align: 'center',},
      //   {title: '负责人', value: 'leader_name', width: 100, align: 'center', header_align: 'center'},
      //   {title: '备注信息', value: 'remark', width: 120, align: 'center', header_align: 'center'},
      //   {title: '店铺名称', value: 'company_name', width: 100, align: 'center', header_align: 'center'},
      //
      // ],
      open: false,
      openLog: false,//日志模态框
      currId: null,
      copyRow: null,
      currentRow: {},
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      //导入相关
      openImport: false,
      mod: 'Calendar',
      andMore: false,
      moreArray: [],
      multipleSelection: [],
      showPreview: false,
      pptData: {},
      isInitLoading: true,
      showJoinlive: false,
      productId: '',
      menuList: {
        edit: true,
        log: true,
        del: true,
        copy: false
      },
      dialogStore: false,
      formStore: {
        nu: null,
        com: null,
        com_label: null,
        qty: 1,
        need_back: null,
        remark: null
      },
      popperVisible: false,
      openDetails: false,
      rowDetails: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    handleCheck(val) {
      // this.pptData = val
      this.currId = val.id
      this.showPreview = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.searchCondition.category_id = null
      this.searchCondition.category_ids = null
      if (v.length) {
        this.searchCondition.category_id = v[(v.length - 1)]
        this.searchCondition.category_ids = v
      }
      this.handleQuery()
    },
    refreshLeader() {
      this.$refs['refProductLeader'].getOptions()
      this.handleQuery()
    },
    selectedLeader() {
      // this.searchCondition.leader_id=item.value
      this.handleQuery()
    },
    async getList() {
      this.loading = true
      this.dataList = []
      this.moreArray = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pageData, { order: this.orderSort })
      let { list, pages } = await this.$api.getProductList(searchCondition)
      this.dataList = list || []
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 1
      this.total = pages.total
      this.loading = false
      this.isInitLoading = false
    },

    changeTableSort({ prop, order }) {
      let orderSort = {}
      if (order) {
        orderSort[prop] = order === 'descending' ? 'desc' : 'asc'
      }
      this.orderSort = orderSort
      this.handleQuery()
    },
    async getBrand(force) {
      this.loadingBrandStatus = true
      if (this.brandOptions.length === 0 || force) {
        // let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
        let { options } = await this.$api.getBrandOptions()
        this.brandOptions = options
        this.loadingBrandStatus = false
      }
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
    },
    async asyncExportExcel() {
      this.exportLoading = true
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, { order: this.orderSort })

      let { task_id } = await this.$api.asyncExportProduct(searchCondition)
      // this.$notify.warning(task_id)
      let msg = '<strong>点击这里 <a href=\'/download_file\' target=\'_blank\'>下载</a> 生成的文件:' + task_id + '</strong>'
      this.$notify({
        title: '导出文件生成中',
        dangerouslyUseHTMLString: true,
        message: msg,
        type: 'success',
        duration: 0
      })
      this.exportLoading = false
    },
    async exportExcel() {
      try {
        let searchCondition = this.handleSearchCondition()
        Object.assign(searchCondition, { order: this.orderSort })
        let name = `产品库`
        let response = await this.$api.exportProduct(searchCondition)

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    },
    handleSearchCondition() {
      let cond = {}
      // if (this.searchCondition.category_id) {
      //   cond['category_id'] = this.searchCondition.category_id
      // }
      if (this.searchCondition.category_ids) {
        cond['category_ids'] = this.searchCondition.category_ids
      }
      if (this.searchCondition.title) {
        cond.title = this.searchCondition.title
      }
      if (this.searchCondition.leader_id) {
        cond['leader_id'] = this.searchCondition.leader_id
      }
      if (this.searchCondition.brand_id) {
        cond['brand_id'] = this.searchCondition.brand_id
      }
      if (this.searchCondition.unify) {
        cond['unify'] = this.searchCondition.unify
      }
      if (this.searchCondition.date_range) {
        cond['date_range'] = this.searchCondition.date_range
      }
      if (this.searchCondition.platform_name) {
        cond['platform_name'] = this.searchCondition.platform_name
      }
      if (this.userPermissions.indexOf('product_view_all') === -1) {
        cond['leader_id'] = this.userInfo.id
      }
      if (this.searchCondition.is_sec_kill) {
        cond['is_sec_kill'] = this.searchCondition.is_sec_kill
      }
      if (this.searchCondition.used_season) {
        cond['used_season'] = this.searchCondition.used_season
      }
      // 线上佣金
      cond['online_cmn'] = this.searchCondition.online_cmn || null

      if (this.price[1] !== undefined || this.price[1] != null) {
        this.price[0] = 0
      }
      this.price=this.price.map((item)=>{return Number(item)})
      cond[this.modePrice] =  this.price
      // platform_name
      return cond
    },
    handleEdit(row) {
      this.copyRow = {}
      this.currId = row.id
      this.open = true
    },
    showLog(row) {
      this.copyRow = {}
      this.currId = row.id
      this.openLog = true
    },
    handleCopy(row) {
      // let ids = row.category_ids.replace(/\[|]/g, '').split(',')
      this.copyRow = Object.assign({}, row)
      this.copyRow.category_ids = row.category_ids
      delete this.copyRow.leader_id
      delete this.copyRow.leader_name
      delete this.copyRow.id
      delete this.copyRow.created_at
      delete this.copyRow.updated_at

      this.currId = null
      this.open = true
    },
    handleAdd() {
      this.currId = null
      this.copyRow = {}
      this.open = true
    },
    async delInfo(id) {
      let info = await this.$api.delProduct(id)
      if (info) {
        await this.getList()
        this.msgSuccess('删除成功')
      }
    },
    handleDelete(row) {
      const id = row.id
      this.$confirm(`是否确认删除产品【${row.title}/${row.category}/${row.spec_name}】?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo(id)
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },
    handleBatchDelete: function() {
      const ids = this.multipleSelection.map(_ => {
        return _.id
      })
      if (!ids.length) {
        return this.$message.warning('请选择要删除的产品')
      }

      this.$confirm(`已选中${ids.length}个产品，确认删除吗？`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.$api.delProduct(ids)
        await this.getList()
        this.msgSuccess('删除成功')
      })
    },
    //右键菜单关闭
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    },
    // eslint-disable-next-line no-unused-vars
    openMenu(row, column, e) {
      this.currentRow = row
      // this.$notify.info(`右键菜单:${e.clientY}`)
      e.preventDefault()//阻止元素发生默认的行为
      // const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      // const offsetTop = 0// this.$el.getBoundingClientRect().top; // container margin top

      this.menuData = {
        visible: true,
        left: e.pageX,//- offsetLeft
        top: e.pageY// - offsetTop
      }
      document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
    },
    handleCmd(cmd) {
      switch (cmd) {
        case '1':
          this.handleEdit(this.currentRow)
          break
        case '2':
          this.handleCopy(this.currentRow)
          break
        case '3':
          this.handleDelete(this.currentRow)
          break
        case 'log':
          this.showLog(this.currentRow)
          break
      }
    },
    handleJoinLive(row) {
      this.showJoinlive = true
      this.productId = row.id
    },
    handleAddSpecimen() {
      if (this.multipleSelection.length <= 0) {
        this.$message.warning('至少选择一件商品')
      } else {
        this.popperVisible = true
      }
    },
    async handelStore() {
      let { nu, com, qty, need_back, remark, com_label } = this.formStore
      let items = this.multipleSelection.map((i) => {
        return {
          product_id: i.id,
          qty: qty,
          need_back: need_back
        }
      })
      let data = {
        nu: nu,
        com: com,
        com_label: com_label,
        remark: remark,
        items: items
      }
      let id = await this.$api.addLbProductSpecimenCommit(data)
      if (id) {
        this.$message.success('新增成功！')
        this.dialogStore = false
        await this.getLogisticList()
        this.popperVisible = false
      } else {
        this.$message.error('未知错误,请联系管理员')
      }
    },
    delMultiple(row) {
      let index = this.multipleSelection.findIndex(function(o) {
        return o.id === row.id
      })
      this.multipleSelection.splice(index, 1)
    },
    handleClose() {
      this.popperVisible = false
    },
    getSelectionProducts: function() {
      return this.$refs.multipleTable ? this.$refs.multipleTable.selection : []
    },
    handleDetails(row) {
      this.rowDetails = row
      this.openDetails = true
    },
    callbackProductTransfer: function() {
      this.$refs.multipleTable.clearSelection()
      this.handleQuery()
    },
    toHandover: function() {
      const routeUrl = this.$router.resolve({
        name: 'productHandover'
      })
      window.open(routeUrl.href, '_blank')
    }
  },
  mounted() {
  }
}
</script>

<style lang='scss' scoped>
@import "../../style/main.scss";


.box_content {
  width: 100%;
  height: auto;
  //border: 1px solid red;
  //border: 1px solid red;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  justify-content: center;
  //flex-direction: column;

  > .card {
    cursor: pointer;
    width: calc((100% / 5) - 50px);
    min-width: 300px;
    margin: 10px auto;
    //max-height: 280px;
    float: left;
    padding-bottom: 10px;
  }
}

@media(max-width: 1700px) {
  .box_content {
    width: 100%;
    //min-width: 1080px;
    //overflow: scroll;

    > .card {
      width: calc((100% / 5) - 26px);
      min-width: 180px;
      margin: 10px auto;


    }
  }
}


</style>
<style>
.popperClass {
  max-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>

