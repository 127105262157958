<template>
  <div>
    <product-history-sync v-if='formData&&formData.title' ref='sync' :product-name='formData.title' :product-id='id' />
    <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' width='70%'>
      <div slot='title'>
        <span style='font-size: 18px;'>{{ dialogTitle }}</span>
        <b v-if='formData&&formData.updated_at' style='color: darkred'>（最后更新时间：{{ formData.updated_at }}）</b>
      </div>
      <div>
        <el-tabs v-model='activeName' type='card' @tab-click='tabClick'>
          <el-tab-pane label='基本信息' name='first'>
            <el-card>
              <el-form ref='formBase' size='mini' :model='formData' :rules='ruleMap.base' label-width='150px'
                       @submit.native.prevent>
                <el-row :gutter='15'>
                  <el-col :span='14'>
                    <el-form-item label='可播平台' prop='platform_code'>
                      <PlatformSelect @saved='handleSelectPlatform' :range-all='true'
                                      placeholder='请选择可播平台'
                                      :default-code.sync='formData.platform_code' />
                    </el-form-item>
                    <el-form-item label='商品名' prop='title'>
                      <el-input v-model='formData.title' placeholder='输入商品名' maxlength='100' show-word-limit
                                clearable />
                    </el-form-item>
                    <el-form-item label='规格' prop='spec_name'>
                      <el-input v-model='formData.spec_name' placeholder='规格' maxlength='100' show-word-limit
                                clearable />
                    </el-form-item>
                    <el-form-item label='色号/香型' prop='spec_color'>
                      <el-input v-model='formData.spec_color' clearable maxlength='100' placeholder='色号'
                                show-word-limit />
                    </el-form-item>
                    <el-form-item label='类目' required>
                      <CategoryCascader :identify='`product`' @cascaderChange='cascaderChange'
                                        :selected-list.sync='selectedList' />
                    </el-form-item>
                    <el-form-item label='季节性'>
                      <el-radio-group v-model="formData.used_season">
                        <el-radio label="春"></el-radio>
                        <el-radio label="夏"></el-radio>
                        <el-radio label="秋"></el-radio>
                        <el-radio label="冬"></el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label='合作方式' required>
                      <CategoryCascader :identify='`mode`' @cascaderChange='cascaderModeChange'
                                        :selected-list.sync='selectedModeList' />
                    </el-form-item>
                  </el-col>
                  <el-col :span='10'>
                    <el-form-item label='' label-width='10px'>
                      <div style='border: #e97ca1 1px dashed;padding: 10px;border-radius: 5px;display: inline-block'>
                        <product-image-upload :key='`piu_${formData.id||0}`' :img-url='formData.cover_url'
                                              @uploaded='getCover' />
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span='12'>
                    <el-form-item label='产品链接' prop='product_url'>
                      <el-input v-model='formData.product_url' placeholder='产品链接' maxlength='300' show-word-limit
                                clearable>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='有效期' prop='period_validity'>
                      <el-input v-model='formData.period_validity' placeholder='有效期' maxlength='50' show-word-limit
                                clearable>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span='12'>
                    <el-form-item label='日常价(元)' prop='price'>
                      <el-input v-model='formData.price' placeholder='日常价' maxlength='200' show-word-limit clearable>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='直播价(元)' prop='lb_price'>
                      <el-input v-model='formData.lb_price' placeholder='直播价' maxlength='200' show-word-limit
                                clearable>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='坑位费' prop='ad_space_fee'>
                      <template slot='label'>
                        <span>坑位费(元)</span>
                      </template>
                      <el-input v-model='formData.ad_space_fee' placeholder='坑位费，保留2位小数' clearable>
                        <span slot='append'>元</span>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='线上佣金(%)' prop='online_cmn'>
                      <template slot='label'>
                        <span>线上佣金(<b class='rate-txt'>%</b>)</span>
                      </template>
                      <el-input v-model='formData.online_cmn' placeholder='线上佣金，比如：10%'
                                clearable>
                        <span slot='append'>%</span>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='线下佣金(%)' prop='offline_cmn'>
                      <template slot='label'>
                        <span>线下佣金(<b class='rate-txt'>%</b>)</span>
                      </template>
                      <el-input v-model='formData.offline_cmn' placeholder='线下佣金，比如：10%'
                                clearable>
                        <span slot='append'>%</span>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span='24'>
                    <el-form-item label='产品产地' prop='origin_place'>
                      <el-input v-model='formData.origin_place' placeholder='产品产地' maxlength='100' show-word-limit
                                clearable />
                    </el-form-item>
                  </el-col>
                  <el-col :span='24'>
                    <el-form-item label='赠品' prop='gift'>
                      <el-input v-model='formData.gift' placeholder='赠品' maxlength='200' show-word-limit clearable />
                    </el-form-item>
                    <el-form-item label='是否签有独家' prop='exclusive' required>
                      <el-radio-group v-model='formData.exclusive'>
                        <el-radio :label='1'>是</el-radio>
                        <el-radio :label='2'>否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label='独家机制' v-show='formData.exclusive==1' prop='other_activity'>
                      <el-input v-model='formData.other_activity' placeholder='独家机制' maxlength='500' show-word-limit
                                clearable />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span='12'>
                    <el-form-item label='负责人' prop='leader_id'>
                      <div style='display: inline-block'>
                        <el-link title='产品负责人' @click='editLeader=!editLeader' v-if='formData.leader_id'
                                 style='margin-right: 10px'>
                          {{ formData.leader_name }}
                        </el-link>
                        <UserSelect v-if='editLeader||!formData.leader_id' :keyword='formData.leader_name'
                                    @selected='handleUserSelect' />

                        <el-button title='修改负责人' type='text' icon='el-icon-edit-outline'
                                   @click='editLeader=!editLeader'
                                   v-if='formData.leader_id'></el-button>
                      </div>

                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span='12'>
                    <el-form-item label='是否全网统一机制' prop='unify'>
                      <el-radio-group v-model='formData.unify'>
                        <el-radio :label='1'>是</el-radio>
                        <el-radio :label='2'>否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12' v-show='formData.unify===2'>
                    <el-form-item label='特殊机制' prop='other_activity'>
                      <el-input v-model='formData.special_remark' placeholder='特殊机制' maxlength='500' show-word-limit
                                clearable />
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='是否可签保价1个月' prop='support_value'>
                      <el-radio-group v-model='formData.support_value'>
                        <el-radio :label='1'>是</el-radio>
                        <el-radio :label='2'>否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='是否特殊化妆品' prop='is_special'>
                      <el-radio-group v-model='formData.is_special'>
                        <el-radio :label="'Y'">是</el-radio>
                        <el-radio :label="'N'">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='是否医疗器械' prop='is_medical'>
                      <el-radio-group v-model='formData.is_medical'>
                        <el-radio :label="'Y'">是</el-radio>
                        <el-radio :label="'N'">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='是否秒杀品' prop='is_sec_kill'>
                      <el-radio-group v-model='formData.is_sec_kill'>
                        <el-radio :label="'Y'">是</el-radio>
                        <el-radio :label="'N'">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='历史达播销量'>
                      <el-input v-model='formData.sales_volume' placeholder='如李佳琦7淘宝/500w/11月1日' maxlength='50'
                                show-word-limit
                                clearable />
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='店铺名称' prop='company_name'>
                      <el-input v-model='formData.company_name' placeholder='店铺名称' maxlength='100' show-word-limit
                                clearable />
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='店铺评分'>
                      <!--                <el-rate show-score text-color='#ff9900'-->
                      <!--                         @change='btnScore' allow-half score-template='{value}'-->
                      <!--                         v-model='formData.company_score'></el-rate>-->
                      <el-input type='text' v-model='formData.company_score' placeholder='店铺评分' maxlength='10'
                                show-word-limit
                                clearable />
                    </el-form-item>
                  </el-col>
                  <el-col :span='24'>
                    <el-form-item label='备注信息' prop='remark'>
                      <el-input v-model='formData.remark' placeholder='备注信息' maxlength='255' show-word-limit
                                clearable />
                    </el-form-item>
                  </el-col>
                  <el-col :span='24'>
                    <el-form-item label='亮点卖点'>
                      <ApeEditor :editorHeight='300' :init-html='formData.light_spot'
                                 :inline='true'
                                 :showMenu='false' @handleTinymceInput='handleTinymceInput'
                                 :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify | bullist numlist' +
          ' | outdent indent blockquote | removeformat anchor hr |' +
          ' code preview fullscreen'"></ApeEditor>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item>
                  <el-button type='primary' size='medium' @click.stop.native='handleConfirm("formBase")'
                             :disabled='loadingCommit'
                             v-if='showOptBtn' :loading='loadingCommit'>
                    {{ formData.id ? '提交' : '添加' }}
                  </el-button>
                </el-form-item>
              </el-form>
            </el-card>
          </el-tab-pane>
          <el-tab-pane label='归属信息' v-if='hasApprove' name='third' :disabled='!formData.id'>
            <el-form ref='formBelong' size='mini' :model='formData' :rules='ruleMap.belong' label-width='150px'>
              <el-card>
                <el-row>
                  <el-col :span='12'>
                    <el-form-item label='店铺注册公司' prop='customer_id'>

                      <el-select v-if='editCustomer' clearable v-model='formData.customer_id' filterable
                                 @change='setSelectValue'
                                 style='width: 100%'
                                 placeholder='请选择公司'>
                        <el-option
                          v-for='item in companyOption'
                          :key='`${item.id}${item.name}`'
                          :label='item.name'
                          :value='item.id'>
                        </el-option>
                      </el-select>
                      <div v-else> {{ formData.customer_name || '--' }}
                        <el-link v-if='formData.id' type='primary' :title='`${formData.customer_id}`'
                                 @click='showCustomerSelection'
                                 :icon='`${loadingCustomerStatus?"el-icon-loading":"el-icon-edit"}`'>
                        </el-link>
                      </div>

                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='品牌' prop='brand_id'>
                      <template slot='label'>
                        <el-link type='primary' @click='getBrand'>品牌<i class='el-icon-refresh'></i></el-link>&nbsp;
                        <el-link type='primary' icon='el-icon-plus' @click='appendBrand'
                                 v-if="userPermissions.indexOf('brand_hub_brand_create') !== -1"></el-link>
                      </template>
                      <div>
                        <el-select v-if='editBrand' clearable v-model='formData.brand_id'
                                   filterable
                                   @change='setSelectBrand'
                                   default-first-option style='width: 100%' placeholder='请选择'>
                          <el-option
                            v-for='item in brandOptions'
                            :key='item.id'
                            :label='item.name'
                            :value='item.id'>
                          </el-option>
                        </el-select>
                        <div v-else>{{ formData.brand_name || '--' }}
                          <el-link v-if='formData.id' type='primary' :title='`${formData.brand_id}`'
                                   @click='showBrandSelection'
                                   :icon='`${loadingBrandStatus?"el-icon-loading":"el-icon-edit"}`'>
                          </el-link>
                          <el-tooltip content='关联后，在品牌库详情页面中的“关联产品”可查看' placement='right'
                                      effect='light'>
                            <el-button icon='el-icon-info' type='text' style='float: right'></el-button>
                          </el-tooltip>
                        </div>


                      </div>
                    </el-form-item>
                  </el-col>
                  <el-col :span='24'>
                    <el-form-item label='品牌背书' prop='intro'>
                      <el-input v-model='formData.intro' placeholder='品牌背书' maxlength='400' show-word-limit
                                :autosize='{ minRows: 8, maxRows: 20}'
                                type='textarea' clearable />
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='联系人' prop='contact_user'>
                      <el-input v-model='formData.contact_user'></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span='12'>
                    <el-form-item label='联系方式' prop='contact_info'>
                      <el-input v-model='formData.contact_info' style='width: 200px'></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span='24'>
                    <el-form-item label='物流信息'>
                      <el-input v-model='formData.delivery_info' clearable maxlength='255'
                                :rows='4' type='textarea' show-word-limit />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item>
                  <el-button type='primary' size='medium' @click.stop.native='handleConfirm("formBelong")'
                             :disabled='loadingCommit'
                             v-if='showOptBtn' :loading='loadingCommit'>
                    {{ formData.id ? '提交' : '添加' }}
                  </el-button>
                </el-form-item>
              </el-card>
            </el-form>
          </el-tab-pane>
          <!--          product_specimen_record-->
          <el-tab-pane v-if="userPermissions.indexOf('product_specimen_record')>-1" :disabled='!formData.id'
                       label='寄样信息' name='fourth'>
            <specimen-record v-if='specimenShow' :id='this.formData.id' :product='formData' />
          </el-tab-pane>
          <el-tab-pane label='选品记录' name='KolSelectionRecord'>
            <KolSelectionRecord ref='refKolSelectionRecord'></KolSelectionRecord>
          </el-tab-pane>
          <el-tab-pane label='直播记录' name='LiveSelectionRecord'>
            <LiveSelectionRecord ref='refLiveSelectionRecord'></LiveSelectionRecord>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'
import ApeUploader from '@/components/ApeUploader'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import ProductImageUpload from '@/pages/lb/product/ProductImageUpload'
import UserSelect from '@/pages/lb/components/UserSelect'
import { mapGetters } from 'vuex'
import ApeEditor from '@/components/ApeEditor'
import SpecimenRecord from '@/pages/lb/product/SpecimenRecord'
import ProductHistorySync from './history/sync.vue'
import KolSelectionRecord from '@/pages/lb/product/selection/KolSelectionRecord'
import LiveSelectionRecord from '@/pages/lb/product/selection/LiveSelectionRecord'

export default {
  name: 'ProductCreateEdit',
  components: {
    LiveSelectionRecord,
    KolSelectionRecord,
    ProductHistorySync,
    UserSelect,
    ProductImageUpload,
    CategoryCascader,
    ApeUploader,
    PlatformSelect,
    ArtistSearch,
    ApeEditor,
    SpecimenRecord
  },
  props: {
    id: {
      type: [Number, String]
    }
    ,
    defaultFormData: {
      type: Object,
      default
        () {
        return {
          title: '',
          spec_name: '',
          cover: null,
          cover_url: '',
          product_url: '',
          category_id: null,
          category_2_id: null,
          category_ids: null,
          mode: 1,
          price: 0,
          lb_price: 0,
          ad_space_fee: 0,
          online_cmn: 0,
          offline_cmn: 0,
          company_score: 0,
          gift: '',
          other_activity: '',
          remark: '',
          company_name: '',
          leader_id: null,
          leader_name: null,
          origin_place: null,
          is_special: false,
          is_sec_kill: false,
          unify: null,
          is_medical: null,
          used_season:null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    dialogTitle() {
      return `${this.id ? `修改 ${this.formData.title}` : '添加'} 产品信息`
    }
  },
  data() {
    return {
      activeName: 'first',
      loadingStatus: false,
      loadingCustomerStatus: false,
      loadingBrandStatus: false,
      loadingCommit: false,
      // 已上传图片列表
      uploadFileList: [],
      allowTypeList: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif', 'image/bmp'],
      cascaderProps: {
        label: 'display_name',
        value: 'id'
      },
      // 已选中的分类列表，用于cascader组件选中效果
      selectedList: [],
      selectedModeList: [],
      disabledEditPic: false,
      editLeader: false,
      formData: {
        // title: '',
        // spec_name: '',
        // spec_color: '',
        // cover: null,
        // cover_url: '',
        // product_url: '',
        // category_id: null,
        // category_2_id: null,
        // category_ids: null,
        // mode: 1,
        // price: 0,
        // lb_price: 0,
        // ad_space_fee: 0,
        // online_cmn: 0,
        // offline_cmn: 0,
        // company_score: null,
        // gift: '',
        // other_activity: '',
        // remark: '',
        // company_name: '',
        // leader_id: null,
        // leader_name: null,
        // support_value: null,
        // origin_place: null,
        // is_special: false,
        // unify: null,
        // is_medical: null,
        // contact_info: null,
        // platform_code: [],
        // special_remark: '',
        // exclusive: null,
        // period_validity: ''
      },
      // //1：纯佣 2：佣金+坑位 3：全案
      // modeOptions: [
      //   {label: '年框', value: 4},
      //   {label: '纯佣', value: 1},
      //   {label: '佣金+坑位', value: 2},
      //   {label: '全案', value: 3},
      // ],
      lbTimeRange: [],
      ruleMap: {
        base: {
          title: { required: true, message: '商品名称必填', trigger: 'change' },
          spec_name: { required: true, message: '规格必填', trigger: 'change' },
          category: { required: true, message: '类目必填', trigger: 'change' },
          price: { required: true, message: '日常必填', trigger: 'change' },
          lb_price: { required: true, message: '直播价格必填', trigger: 'change' },
          // online_cmn: { required: true, message: '线上佣金必填', trigger: 'change' },
          // offline_cmn: { required: true, message: '线下佣金必填', trigger: 'change' },
          // gift: { required: false, message: '赠品', trigger: 'change' },
          category_id: { required: true, message: '类目必选', trigger: 'change' },
          // product_url: { required: true, message: '产品链接', trigger: 'change' },
          leader_id: { required: true, message: '负责人', trigger: 'change' },
          exclusive: { required: true, message: '请选择是否签有独家', trigger: 'change' },
          is_sec_kill: { required: true, message: '请选择是否秒杀', trigger: 'change' }
        },
        other: {
          unify: { required: true, message: '是否全网统一机制必选', trigger: 'change' },
          support_value: { required: true, message: '是否可签保价1个月必选', trigger: 'change' },
          is_special: { required: true, message: '请选择是否特殊化妆品', trigger: 'change' },
          is_medical: { required: true, message: '请选择是否医疗器械', trigger: 'change' }
        },
        belong: {
          customer_id: { required: true, message: '店铺注册公司必填', trigger: 'blur' },
          brand_id: { required: true, message: '品牌必填', trigger: 'blur' }
        }
      },

      options: [],
      companyOption: [],
      brandOptions: [],
      editBrand: false,
      editCustomer: false,
      showOptBtn: false,
      specimenShow: false,
      hasApprove: true,
      form_allow: []
    }
  },
  methods: {
    onOpen() {
      this.activeName = 'first'
      this.disabledEditPic = true
      this.editLeader = false
      this.loadingCommit = false
      this.editCustomer = false
      this.editBrand = false
      this.formData = {
        title: null,
        spec_name: null,
        cover: null,
        cover_url: null,
        product_url: null,
        category_id: null,
        category_2_id: null,
        category_ids: null,
        mode: null,
        price: null,
        lb_price: null,
        ad_space_fee: null,
        online_cmn: null,
        offline_cmn: null,
        gift: '',
        other_activity: null,
        remark: null,
        company_name: null,
        leader_id: null,
        leader_name: null,
        brand_id: null,
        customer_id: null,
        company_score: 0,
        origin_place: null,
        is_special: false,
        support_value: null,
        unify: null,
        is_medical: null,
        platform_code: null,
        special_remark: '',
        exclusive: null,
        period_validity: '',
        spec_color: '',
        used_season:null
      }
      // 类目
      this.selectedList = []
      // this.selectedList = this.formData.category_ids && this.formData.category_ids != null ? this.formData.category_ids : [this.formData.category_id]
      this.selectedModeList = []
      // 显示产品图片
      this.uploadFileList = []

      if (this.id) {
        //修改操作
        this.formData.id = this.id
        this.getInfo()
      } else {
        //新增操作
        this.canOpt()
      }


    },
    onClose() {
      this.formData = {}
      // this.$refs['elForm'].resetFields()
      // this.$emit('refreshData')
    },
    close() {
      this.$emit('update:visible', false)
    },
    //可操作
    canOpt() {
      this.showOptBtn = false
      if (this.formData.id) {
        //修改权限
        this.showOptBtn = this.userPermissions.indexOf('product_edit_all') > -1 || (this.userPermissions.indexOf('product_edit') > -1 && this.formData.leader_id === this.userInfo.id)
      } else
        this.showOptBtn = this.userPermissions.indexOf('product_create') > -1
      if (this.form_allow) {
        let index = this.form_allow.findIndex((i) => {
          return i === 'formBelong'
        })
        this.hasApprove = index > 0
      }
    },
    //编辑器
    handleTinymceInput(val) {
      this.formData.light_spot = val
    },
    // btnScore(val) {
    //   this.formData.company_score = Number(val)
    // },
    setSelectValue(val) {
      let option = this.companyOption.find(value => value.id === val)
      this.formData.customer_name = option ? option.name : ''
    },
    setSelectBrand(val) {
      let option = this.brandOptions.find(value => value.id === val)
      this.formData.brand_name = option ? option.name : ''
    },
    async getBrand() {
      this.loadingBrandStatus = true
      // let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
      let { options } = await this.$api.getBrandOptions()
      this.brandOptions = options
      this.loadingBrandStatus = false
    },
    async showCustomerSelection() {
      await this.getCompanyOption()
      //显示客户筛选区域
      this.editCustomer = true
    },
    async showBrandSelection() {
      await this.getBrand()
      //显示品牌筛选区域
      this.editBrand = true
    },
    appendBrand() {
      //追加品牌
      // this.$notify.warning('功能开发中，请先自行前往品牌管理页面新增')
      this.routerJump('product_brand', {}, {}, '_blank')
    },
    getCover(val) {
      this.formData.cover = val.id || null
      this.formData.cover_url = val.full_path || null
      this.uploadFileList = [{ id: val.id, url: val.full_path }]
      this.$forceUpdate()
    },
    async getInfo() {
      let { info, form_allow } = await this.$api.getProductInfo(this.formData.id)
      info.brand_id = Number(info?.brand_id) || null
      info.customer_id = Number(info?.customer_id) || null
      // info.company_score = Number(info?.company_score) || null
      this.id = info.id
      this.formData = { ...info }
      this.selectedList = info.category_ids && info.category_ids != null ? info.category_ids : [info.category_id]
      this.selectedModeList = [info.mode]
      //显示产品图片
      // this.uploadFileList = [{url: info.cover_url}]
      this.uploadFileList = info.cover_url && info.cover_url.indexOf('://') > -1 ? [{ url: info.cover_url }] : []
      //计算权限
      this.form_allow = form_allow
      this.canOpt()
    },
    selectArtist(row) {
      this.formData.artist_id = row.id || ''
      this.formData.nickname = row.nickname || ''
    },
    handleConfirm(refFormName) {
      this.$refs[refFormName].validate(valid => {
        if (!valid) {
          this.$message.error('数据验证失败，请检查必填项数据！')
          return
        }

        // if (!this.formData.category_id || !this.formData.mode) {
        //   this.$message.error('数据验证失败，请检查必填项数据[类目、合作方式]！')
        //   return
        // }

        this.saveInfo(refFormName)
      })

    },
    async saveInfo(formName) {
      let formFieldMap = {
        formBase: ['platform_code', 'platform_name', 'title', 'spec_name', 'spec_color', 'category_id', 'category_2_id', 'mode', 'product_url',
          'cover', 'cover_url', 'price', 'lb_price', 'ad_space_fee', 'online_cmn', 'offline_cmn', 'origin_place', 'gift',
          'other_activity', 'leader_id', 'leader_name', 'exclusive', 'period_validity', 'unify', 'support_value', 'is_special', 'is_medical', 'company_name', 'sales_volume', 'company_score', 'remark', 'light_spot', 'special_remark',
          'is_sec_kill','used_season'
        ],
        // formOther: ['unify', 'support_value', 'is_special', 'is_medical', 'company_name', 'sales_volume', 'company_score', 'remark', 'light_spot', 'special_remark'],
        formBelong: ['customer_id', 'customer_name', 'brand_id', 'brand_name', 'intro', 'contact_user', 'contact_info', 'delivery_info']
      }
      let postData = {}
      let fields = formFieldMap[formName]
      //字段范围
      fields.forEach((field) => {
        postData[field] = this.formData[field]
        if (formName === 'formBase') {
          postData['category_ids'] = [this.formData.category_id]
          if (this.formData.category_2_id) {
            postData['category_ids'].push(this.formData.category_2_id)
          }
        }
      })
      if (this.formData.id) {
        postData['id'] = this.formData.id
      }

      if (formName === 'formBase') {
        if (!this.formData.category_id) {
          this.msgWarn('类目必填！！！')
          return
        }
        if (!postData['mode']) {
          this.msgWarn('合作方式必填！！！')
          return
        }
      }

      if (!postData['id'] && formName !== 'formBase') {
        this.msgWarn('新增操作，请先创建商品基本信息')
        return
      }
      this.loadingCommit = true
      const { id, version } = await this.$api.saveProductPart(formName, postData)
      if (id) {
        this.$notify.success('保存成功')
        this.formData.id = id
        await this.getInfo()
        this.loadingCommit = false
        if (version) {
          this.$confirm(`需要同步已排期产品信息吗？`, '提示', {
            confirmButtonText: '去同步',
            cancelButtonText: '不同步',
            type: 'warning'
          }).then(async () => {
            this.$refs.sync.handleShowDrawer(version)
          }).catch(() => {
          })
        }
      }
      setTimeout(() => {
        this.loadingCommit = false
      }, 1000)
    },
    changePlatform(val) {
      this.formData.platform_code = val
    },

    // 级联菜单组件change事件
    cascaderChange(v) {
      this.formData.category_id = null
      this.formData.category_2_id = null
      if (v.length) {
        this.formData.category_id = v[0]
        if (v.length === 2) {
          // this.formData.category_id = v[0]
          this.formData.category_2_id = v[1]
        }
        this.formData.category_ids = v
      }
    },
    cascaderModeChange(v) {
      this.formData.mode = null
      if (v.length) {
        this.formData.mode = v[(v.length - 1)]
        // this.formData.category_ids = v
      }
    },
    // 图片上传成功回调
    handleUploadSuccess(file, fileList) {
      this.formData.cover = file.id
      this.formData.cover_url = file.full_path || null
      this.uploadFileList = fileList
    },
    // 图片删除回调
    handleUploadRemove(file, fileList) {
      this.formData.cover = 0
      this.formData.cover_url = null
      this.uploadFileList = fileList
    },
    // 附件上传成功回调
    handleAttachmentUploadSuccess(file, fileList) {
      this.formData.attachment = file.id
      this.attachmentUploadFileList = fileList
    },
    // 附件删除回调
    handleAttachmentUploadRemove(file, fileList) {
      this.formData.attachment = 0
      this.attachmentUploadFileList = fileList
    },

    // 处理搜索结果的选中
    handleUserSelect(item) {
      this.formData.leader_id = item.value || ''
      this.formData.leader_name = item.label || ''
    },
    async getCompanyOption() {
      this.loadingCustomerStatus = true
      //获取公司下拉
      this.companyOption = await this.$api.selectCustomList()
      this.loadingCustomerStatus = false
    },
    handleSelectPlatform(val) {
      this.formData.platform_code = val ? val.code : []
      this.formData.platform_name = val ? val.name : []
    },
    tabClick() {
      if (this.activeName === 'fourth') {
        this.specimenShow = true
      } else if (this.activeName === 'KolSelectionRecord') {
        this.$refs['refKolSelectionRecord'].show({ product_id: this.formData.id })
      } else if (this.activeName === 'LiveSelectionRecord') {
        this.$refs['refLiveSelectionRecord'].show({ product_id: this.formData.id })
      }
    }
    // handleClick(val) {
    //   console.log(val)
    // }

  },
  created() {

  }
}
</script>

