<template>
  <div class='box_content'>
    <template v-if="dataList.length!==0">
      <el-card class='card' v-for='(item) in dataList' :key='item.id' shadow='hover'>
        <el-skeleton animated :loading='loading'>
          <template slot='template'>
            <el-skeleton-item variant='image' style='width: 240px; height: 240px;'/>
            <div style='padding: 14px;'>
              <el-skeleton-item variant='p' style='width: 50%'/>
              <div style='display: flex; align-items: center;'>
                <el-skeleton-item variant='text' style='margin-right: 16px;'/>
                <el-skeleton-item variant='text' style='width: 30%;'/>
              </div>
            </div>
          </template>
          <template>
            <div style="position:relative;">
              <img v-if="item.is_sec_kill==='Y'" src='@/assets/icons/seckill.png' alt=""
                   style="position: absolute;top: -16px;left: -16px;"/>
              <product-image style='height: 100px;margin: 0 auto' :image-url='item.cover_url'/>
            </div>

            <div class='card_content'>
              <br>
              <span :title='item.id'>产品名称：{{ item.title }}</span>
              <span>规格：{{ item.spec_name || '' }} </span>
              <span v-show="item.spec_color&&item.spec_color!==''"> 色号/香型：{{ item.spec_color || '' }} </span>
              <span v-show="item.used_season&&item.used_season!==''">季节性：{{ item.used_season || '' }} </span>
              <span>品牌：{{ item.brand_name || '' }} </span>
              <span>可播平台：{{ item.platform_names || '' }}</span>
              <span>日常价：<a>{{ item.price || '' }}元</a> </span>
              <span>直播价：<a>{{ item.lb_price || '' }}元</a> </span>
              <span>赠品：{{ item.gift || '' }} </span>
              <span>坑位费：<a>{{ item.ad_space_fee || '暂无' }}</a></span>
              <span v-if="item.online_cmn">线上佣金(%)：<a>{{
                  item.online_cmn ? item.online_cmn : '' || '暂无'
                }}</a></span>
              <span v-else>线下佣金(%)：<a>{{ item.offline_cmn ? item.offline_cmn : '' || '/' }}</a></span>
              <span>是否统一机制：{{ item.unify === 1 ? '是' : '否' || '/' }}<a
                  v-if='item.unify===2'> /{{ item.special_remark || '/' }} </a></span>
              <span>是否有签独家：{{ item.exclusive === 1 ? '是' : '否' }}<a
                  v-if='item.exclusive===1'>/{{ item.other_activity || '/' }}</a></span>
              <span>是否签保价协议：{{ item.support_value === 1 ? '是' : '否' || '/' }}</span>
              <span>店铺名称：{{ item.company_name || '/' }}</span>
              <span> 备注：{{ item.remark || '/' }}</span>
              <span> 负责人：{{ item.leader_name || '/' }}</span>
              <div style='width: 100%;display: flex;flex-wrap: wrap'>
                <el-button size='medium' type='text' icon='el-icon-edit' @click='handleEdit(item)'>修改
                </el-button>
                <el-tooltip content='添加到直播选品' placement='top' effect='light'>
                  <el-button size='medium' icon='el-icon-shopping-cart-2' type='text' @click='handleJoinLive(item)'>选品
                  </el-button>
                </el-tooltip>
                <el-tooltip content='预览产品PPT展示' placement='bottom' effect='light'>
                  <el-button size='medium' type='text' icon='el-icon-s-platform' @click='handleCheck(item)'>PPT
                  </el-button>
                </el-tooltip>
                <product-version-list v-if='userPermissions.indexOf("product_version")>-1' :key='item.id'
                                      :product-id='item.id' :product-name='item.title'
                                      style='margin-left: 5px'/>
                <!--                    <el-button type='text' icon='el-icon-document-copy' @click='handleCopy(item)'-->
                <!--                               v-if="userPermissions.indexOf('product_create')>-1">复制-->
                <!--                    </el-button>-->
                <!--                    <el-button type='text' icon='el-icon-delete' @click='handleDelete(item)'-->
                <!--                               v-if="userPermissions.indexOf('product_delete')>-1">删除-->
                <!--                    </el-button>-->
              </div>
            </div>
          </template>
        </el-skeleton>
      </el-card>

    </template>
    <template v-else>
      <h1 class="text"> o(╥﹏╥)o
        <br/> <br/>暂无数据</h1>

    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ProductImage from './ProductImage'
import ProductVersionList from './history/list.vue'

export default {
  name: 'ProductOldCard',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: {
    ProductImage, ProductVersionList
  },
  props: {
    dataList: {
      type: Array
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
    handleEdit(row) {
      this.$emit('handleEdit', row)
    },
    handleJoinLive(row) {
      this.$emit('handleJoinLive', row)
    },
    handleCheck(row) {
      this.$emit('handleCheck', row)
    },
    handleDetails(row) {
      this.$emit('handleDetails', row)
    }
  }
}
</script>
<style lang='scss' scoped>
.card_content {
  > span {
    display: block;
    font-size: 14px;
    margin: 4px 0;
    color: #606266;

    > a {
      color: #c92b60;
    }
  }

  > a {
    font-size: 13px;
    margin: 2px 0;

  }
}

.box_content {
  width: 100%;
  height: auto;
  //border: 1px solid red;
  //border: 1px solid red;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
  justify-content: center;
  //flex-direction: column;

  > .card {
    cursor: pointer;
    width: calc((100% / 5) - 50px);
    min-width: 300px;
    margin: 10px auto;
    //max-height: 280px;
    float: left;
    padding-bottom: 10px;
  }
}

@media(max-width: 1700px) {
  .box_content {
    width: 100%;
    //min-width: 1080px;
    //overflow: scroll;

    > .card {
      width: calc((100% / 5) - 26px);
      min-width: 180px;
      margin: 10px auto;


    }
  }
}


</style>
<style>
.popperClass {
  max-height: 800px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text {
  text-align: center;
  margin: 10% auto;
  font-size: 40px;
  background: -webkit-linear-gradient(-90deg, #FF8B87 30%, #FFD4E3
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

